.sales{
	margin: 20px 0;
	height: 500px;
	width: 100%;
	background: rgb(250, 249, 249);
	display: grid;
	grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 800px) {
  .sales{
    grid-template-columns: 1fr;
    height: auto;
    text-align: center;
  }
  .sales_text{
    height: auto !important;
    margin: 20px auto !important;
  }
}

.sales_img_block{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
    position: relative;
}

.sales_img{
   width: 80%;
   height: 80%;
   object-fit: contain;
}

.sales_text{
	height: 500px;
	width: 80%;
    margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.sales_title{
	font-size: 40px;
	letter-spacing: 3px;
	margin: 20px 0;
}

.sales_deadline{
	color: gray;
	font-size: 22px;
	letter-spacing: 2px;
}