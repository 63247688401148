.table{
  width: 90%;
  margin: 50px auto;
  //border: 1px solid #e7e2e2;
}

.th{
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #e7e2e2 !important;
  height: 50px;
}

.td {
  padding: 10px 20px;
  height: 50px;
  text-align: left;
  border-bottom: 1px solid #e7e2e2 !important;
}

.td_product{
  display: flex;
}

.td_quantity{
  display: flex;
  align-items: center;
}
.td_description{
  margin-left: 20px;
}

.quantity_btn{
  border: none;
  background: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 22px;
  font-weight: 100;
  color: gray;
}

.quantity_btn:hover{
  color: black;
}
.td_description div{
  margin: 5px 0;
  display: flex;
}
.gray{
  color: silver;
  font-size: 14px;
}

.color{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.btn_wrapper{
  width: 90%;
  margin: 20px auto;
  display: flex !important;
  justify-content: space-between !important;
}

.clear_btn{
  padding:  10px 20px;
  background: #514f4f;
  border: none;
  color: whitesmoke;
  letter-spacing: 1px;
  font-size: 12px;
  transition: all 0.3s ease-in;
}

.mobile_clear_btn{
  padding:  10px;
  background: #514f4f;
  border: none;
  color: whitesmoke;
  letter-spacing: 1px;
  font-size: 10px;
  transition: all 0.3s ease-in;
}

.clear_btn:hover{
 border: 1px solid #514f4f;
  background: none;
  color: #514f4f;
}

.modal_form{
  display: flex;
  flex-direction: column;
}

.form_item{
  outline: none !important;
}

.input_modal{
  margin: 10px;
  padding: 5px;
  width: 90% !important;
  border: none;
  outline: none !important;
  border-bottom: 1px solid gray;
  border-radius: 0;
}

.ant-form-item-label>label {
  width: 90px;
}

.modal_logo{
  letter-spacing: 5px;
  text-align: center;
  color: gray;
  font-size: 20px;
  margin: 20px auto;
}

.modal_title{
  letter-spacing: 0.5px;
}

.modal_data{
  margin: 30px 0;
  letter-spacing: 0.5px;
  text-align: center;
  font-size: 16px;
}

.modal_btn_wrapper{
    width: 97%;
    margin: 15px auto;
    display: flex;
    justify-content: flex-end;
}



.mobile_tr{
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  border-bottom: 1px solid #e5e2e2;
  margin: 10px;
}

.mobile_td{
  display: flex;
  width: 90vw;
  height: 100%;
}

.mobile_table{
  margin: 20px auto;
}

.mobile_text_block{
  margin: 10px 30px;
  line-height: 1.8;
  padding-left: 15px;
}

.mobile_title{
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: -10px !important;

}

.mobile_color{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.mobile_color_block{
  display: flex;
  color: gray;
  align-items: center;
}

.mobile_size{
  display: flex;
}

.mobile_total td{
  width: 80vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 475px) {
  .mobile_text_block{
    margin: 10px 5px ;
    line-height: 1.8;
    font-size: 12px;
    width: 45%;
  }

  .mobile_title{
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
  }

  .mobile_tr{
    height: 170px;
    margin-bottom: 20px;
  }

  .mobile_title{
    margin-bottom: 5px;
    margin-top: -15px;
    font-size: 15px;
  }

}

.cart_img{
  width: 100px;
  height: 100px;
}

.cart_mobile_img{
  object-fit: contain;
}

.cart_wrapper{
  margin-top: 70px;
}