.top_bar{
	height: 100px;
	position: relative;
    display: flex;
  align-items: center ;

}

.catalogue_title{
	position: absolute;
	left: 40%;
	letter-spacing: 5px;
	font-weight: 300;
}

@media screen and (max-width: 600px) {
  .catalogue_title{

    left: 30%;

  }
}

.menu_wrapper{
	width: 80%;
	margin: 0 auto;
}

.top_bar_image{
	height: 100%;
	width: 100%;
	object-fit: cover;
}