.category_slider_item{
	background: silver;
	height: 250px;
	width: 97%;
	position: relative;
	z-index: 0;
}

.category_slider_item_img_wrapper{
	overflow: hidden;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;

}

.category_slider_item_text{
	position: absolute;
	top: 35%;
	left: 0;
	z-index: 3;
	width: 180px;
	margin-left: 15px;
  text-decoration: none;
  color: inherit;

}

.category_slider_item_img{
	// position: absolute;
	// // top: 0;
	// // left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 2;
	transition: all 0.5s ease-in;
}

.category_slider_item_img:hover{
	scale: 1.5;
}

.category_slider_item_title{
	font-size: 24px;
	letter-spacing: 3px;
	margin-left: 10px;
	text-align: left;
	line-height: 1.4;
}

.category_slider_item_btn{
	font-size: 10px;
	border: none;
	border-bottom: 1px solid gray;
	margin-top: 20px;
	margin-left: 15px;
	letter-spacing: 2px;
	background: none;
	line-height: 0.8;
	padding: 0 0 5px 0;
}

.my-own-custom-container{
   margin: 20px 0;
   display: flex;
   justify-content: space-between;
   width: 100%;
}

.catalogue_link{
  display: block;
  height: 250px !important;
  width: 97%;
}