.menu{
	height: 80px;
	width: 90vw;
	display: grid;
	grid-template-columns: 1fr 4fr 50px;
	gap: 1rem;
	align-items: center;
	font-weight: 300;
	letter-spacing: 1px;
  grid-template-areas: 'logo menu cart';
  position: fixed;
  margin: 0 auto !important;
  top: 0;
  left: 5vw;
  z-index: 100;
  background: white;
}

@media screen and (max-width: 800px ) and (min-width: 400px) {
  .menu{
    display: grid;
    grid-template-areas: 'logo logo'
                          'menu cart';
    grid-template-columns: 1fr 20px;
    height: auto;
    gap: 0;
  }
 .logo{
   margin: 10px 5px;
 }

  .menu_content{
    margin-bottom: 20px;
    font-size: 14px;
    justify-content: flex-end;

  }

  .menu_icons{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px ) {
  .menu{
    display: grid;
    grid-template-areas: 'logo logo'
                          'menu cart';
    grid-template-columns: 1fr 20px;
    height: auto;
    gap: 0;
  }
  .logo{
    margin: 10px;
    font-size: 20px !important;
  }

  .menu_content{
    margin-bottom: 20px;
    font-size: 12px;
  }

  .menu_icons{
    margin-bottom: 20px;
  }
}

.menu_content{
	display: flex;
	justify-content: flex-end;
	height: 100%;
	align-items: center;
  grid-area: menu;

}
.logo{
	letter-spacing: 5px;
	font-weight: 400;
	font-size: 24px;
    grid-area: logo;
}

.menu_icons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  grid-area: cart;
}

.ordered{
  position: absolute;
  background: #d03333;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: none;
  top: -13px;
  right: -13px;
}

.ant-layout{
  background: none;
}

.menu_item{
  transition: all 0.3s ease-in;
  margin: 0 20px;
}

.menu_item:hover{
 color: gray;
}