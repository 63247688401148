.footer{
	height: auto;
	background: rgb(250, 249, 249);
	display: grid;
	grid-template-columns: 1fr 1.3fr 1fr;
	letter-spacing: 2px;
	gap: 1rem;

}

@media screen and (max-width: 800px){
  .footer{
    grid-template-columns: 1fr;
    padding: 20px;
  }
  .footer_item{
    font-size: 12px !important;
    margin: 15px 0;
  }
  .footer_title{
    font-weight: 600;
    font-size: 12px !important;
    margin: 20px 0 0px 0;
  }
  .footer_block{
    padding: 0  20px!important;
  }
}


.footer_title{
    margin: 40px 0 20px 0;
    font-size: 13px;
	letter-spacing: 2px;
}

.footer_item{
	font-size: 12px;
	margin: 15px 0;
	font-weight: 400;
	// color: rgb(85, 83, 83);
  line-height: 1.5;
  color: #555454;
}

.footer_block{
	padding: 20px;

}

.footer_logo_block{
	display: flex;
	align-items: center;
	justify-content: center;
  font-weight: 700;
}
.links_wrapper{
  display: flex;
  flex-direction: column;
  line-height: 1;
}