
.catalogue_component_wrapper{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, auto));
	gap: 2em;
	justify-content: flex-start;
  height: auto;
  margin: 50px auto;
}

.ant-layout .ant-layout-sider{
  background: none !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline{
  border: none;
}


@media screen and (max-width: 1247px){
  .catalogue_list, .catalogue_wrapper{
    justify-content: center !important;
  }
}

@media screen and (max-width: 914px){
  .catalogue_body{
    display: grid;
    grid-template-columns: 1fr !important;
  }

  .catalogue_sidebar{
    width: 80%;
    margin: 0 auto;
  }

  .ant-layout-sider{
    max-width: 100% !important;
    min-width: 100% !important;

  }
}

.catalogue_component_title{
	text-align: center;
	font-size: 25px;
	letter-spacing: 3px;
    margin-top: 30px;
  height: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.catalogue_item_text{
	padding: 10px 0;
}

.catalogue_body{
	display: grid;
	grid-template-columns: 1fr 5fr;
	margin: 20px auto;
	width: 100%;
}


.catalogue_list{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, auto));
	justify-content: flex-start;
	gap: 2em;
  width: 90%;
  margin: 20px auto;
  height: auto;

}

@media screen and (max-width: 679px)  {
  .catalogue_component_wrapper{
    display: grid;
    justify-content: center;
  }
}

.btn_wrapper{
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: center !important;
}

.catalogue_btn{
  padding: 10px 20px;
  border: 1px solid #a6a5a5;
  margin: 0 auto;
  background: none;
  color: #636262;
  letter-spacing: 0.5px;
  font-size: 12px;
  transition: all 0.3s ease-in;
}

.catalogue_btn:hover{
   scale: 1.05;
  color: white;
  background:  #a6a5a5;
}

.mobile_categories{
  width: 100%;
  margin: 20px auto;
}


.mobile_category_item{
   margin: 10px 0;
  border-radius: 10px;
  color: gray;
  background: #f5f4f4;
  padding: 10px 20px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.mobile_category_item:hover{
  background: #c0bfbf;
  color: white;
}

.catalogue_page{
  margin-top: 70px;
}

