.productDetailBody{
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 30px 40px;
}

.product_details_page{
   margin-top: 70px;
}

@media screen and (max-width: 900px) and (min-width: 500px){
  .productDetailBody{
    grid-template-columns: 1fr;
  }

  .product_details_slider{
    width: 80%;
    margin: 0 auto;
  }

  .products_details_description{
    width: 80%;
    margin: 30px auto;
  }
}

@media screen and (max-width: 500px) {
  .productDetailBody{
    grid-template-columns: 1fr;
    justify-content: center;
    padding: 10px;
  }

  .product_details_slider{
    width: 100% !important;
    margin: 0 auto;
  }

  .products_details_description{
    width: 90% !important;
    margin: 30px auto !important;
    padding: 0 !important;
  }

  .btn_wrapper{
    width: 100%;
    display: flex;
    justify-content: center !important;
  }

  .product_btn{
    margin: 0 auto;
  }
}



.products_details_description{
	padding: 0 50px;
	letter-spacing: 0.5px;
	font-weight: 300;
	line-height: 1.5;
	display: flex;
	flex-direction: column;
}

.products_details_description div{
 margin: 10px 0;
}

.product_price_block{
	display: flex;
}

.product_title{
	font-weight: 500;
	font-size: 20px;
}

.product_sale_price{
	margin-right: 15px !important;
	color: gray;
	text-decoration: line-through;
}

.product_btn{
	background: rgba(67, 65, 65, 0.659);
	color: whitesmoke;
	width: 150px;
	display: flex;
	justify-content: center;
	padding: 10px;
  transition: all 0.3s ease-in;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.product_btn:hover{
 background: none;
  border: 1px solid rgba(67, 65, 65, 0.659);
  color: rgba(26, 26, 26, 0.66);
  scale: 1.01;
}


.product_color{
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

.selected{
  width: 30px;
  height: 30px;
  border: 1px solid silver;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: red;

}

.not_selected{
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: red;

}

.product_colors {
  display: flex;
}

.quantity{
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeaea;
  width: 100px;
}

.quantity_number{
  padding: 0 20px;
}

.quantity_btn{
  border: none;
  background: none;
  font-size: 22px;
  font-weight: 400;
}

.similar_products{
  width: 90%;
  margin: 20px auto;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  justify-content: flex-start;
}

.header{
  font-size: 26px;
  letter-spacing: 2px;
  font-weight: 200;
  width: 90%;
  margin: 30px auto;
}

.bold{
  font-weight: 500;
  font-size: 14px;
}

