.main_slide_wrapper{
	position: relative;
	z-index: 0;
	height: 450px !important;
	width: 100%;
  margin-top: 70px;
}

.main_slider{
	width: 100%;
	height: 450px !important;
}

.slider .slide.selected{
  border: none!important;
}

.main_slide_text{
	position: absolute;
	height: 100%;
	z-index: 10;
	top: 0;
	left: 30px;
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
}

.main_img_wrapper{
	width: 100%;
	height: 600px;

}

.main_slide_img{
	width: 100%;
	height: 600px !important;
	object-fit: cover;
}

 @media screen and (max-width: 850px) and (min-width: 600px){
   .main_slider_title{
     font-size: 32px !important;
     letter-spacing: 2px;
   }

   .main_slider_subtitle{
     font-size: 16px;
     line-height: 1.5;
   }
   .slide_text{
    width: 50%;
     margin: 0 auto;
  }

 }

@media screen and (max-width: 600px) {
  .main_slider_title{
    font-size: 22px !important;
    line-height: 1;
    letter-spacing: 2px;
    font-weight: 600;
  }

  .main_slider_subtitle{
    font-size: 12px;
    line-height: 1.5;
    color: gray;

  }
  .main_slide_text{
    width: 80%;
    margin: 0 auto;
  }
  .main_slide_wrapper{
    position: relative;
    z-index: 0;
    height: 300px !important;
    width: 100%;
  }

  .main_slider{
    width: 100%;
    height: 300px !important;
  }
}




.main_slider_title{
	font-size: 40px;
	line-height: 2;
	letter-spacing: 3px;
}

.main_slider_subtitle{
	font-size: 18px;
	letter-spacing: 2px;
  color: gray;
}

.main_btn_wrapper{
 margin: 20px auto;
}

.main_slider_btn{
	background: rgba(67, 65, 65, 0.632);
	color: white;
	padding: 10px 20px;
	width: 150px;
	letter-spacing: 1px;
	transition: all 0.3s ease-in;
	border: 1px solid transparent;
	font-size: 12px;
}

.main_slider_btn:hover{
	background: whitesmoke;
	border: 1px solid rgba(67, 65, 65, 0.632);
	color: rgba(67, 65, 65, 0.632);
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid grey;
}
.carousel .control-next.control-arrow:before {
	border-left: 8px solid grey;

}

.carousel.carousel-slider .control-arrow:hover {
    background: none;
}

.carousel .slide{
	height: 100%;
}