.add{
	width: 100%;
	height: 300px;
	justify-content: space-between;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	align-items: center;
	transition: all 0.3s ease-in;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .add{
    grid-template-columns: 1fr;
    height: auto;
  }
}

.add_item{
	background: silver;
	width: 100%;
	height: 250px;
	position: relative;
	z-index: 0;
	overflow: hidden;
}

.add_img_block{
	width: 100%;
	height: 100%;
	overflow: hidden;

	transition: all 0.3s ease-in;
}

.add_img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.add_img_block:hover{
	scale: 1.1
}

.add_text{
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	font-size: 24px;
	letter-spacing: 2px;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.add_text_inner{
	width: 90%;
	height: 80%;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
}