.catalogueItem{
	height: 380px;
	width: 290px;
	position: relative;
    transition: all 0.3s ease-in !important;
}

.catalogue_item_img_block{
	height: 300px;
	width: 100%;
	background: rgb(241, 237, 237);
}

.catalogue_item_img{
	width: 100%;
	height: 100%;
	object-fit: cover;
  transition: all 0.3s ease-in !important;

}

.top_position{
  position: absolute;
  top: 20px;
  left: 20px;
}
.bottom_position{
  position: absolute;
  top: 85px;
  left: 20px;
}

.catalogue_item_discount{
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background: rgb(163, 223, 211);
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	color: white;
	letter-spacing: 0.5px;
}

.catalogue_item_new{
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background: rgb(208, 51, 51);
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	color: white;
	letter-spacing: 0.5px;
}

.catalogue_item_price{
	display: flex;
	letter-spacing: 0.5px;
	margin: 10px 0;
	background: none !important;
}

.catalogue_item_discount_price{
	color: gray;
	text-decoration: line-through;
}
.catalogue_item_fact_price{
	font-weight: 600;
}

.catalogue_item_text{
	background: white !important;
  height: 60px;
}

.catalogue_item_title{
  transition: all 0.2s ease-in !important;
}

.addToCart{
  font-size: 16px;
  color: #d03333;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-in !important;

}

